import { Navigate, Outlet } from "react-router-dom";
import { useContext } from 'react'

/* context */
import { Context } from '../../context/UserContext'


const PrivateRoute = () => {
    const  {authenticated} =  useContext(Context)
    const token = localStorage.getItem('token')

    /* async function isAuth(){
       const  {authenticated} = await useContext(Context)
    } */

    
    if(token){
       
        return <Outlet />
    }else{
        
        return <Navigate to="/login" />

    }
/* return authenticated && token ? <Outlet /> : <Navigate to="/login" /> */
}

export default PrivateRoute