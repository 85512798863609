

function InputColorText({
    title,
    handleOnChange,
    nameColor,
    color,
    nameOpacity,
    opacity,
}) 
{
    return (
        <div>
            {title&& <label className="title">{title}</label>}
            <div>
            <div className='box-cor'>
                                    <div>

                                        <input type="color" id={nameColor} name={nameColor} value={color} onChange={handleOnChange} />
                                    </div>



                                    <label for={nameOpacity}>Opacidade: </label>
                                    <input className="input-color-opacity-text" name={nameOpacity} type='text' value={opacity} onChange={handleOnChange} />

                                    <input type="range" id={nameOpacity} name={nameOpacity} min="0" max="100" value={opacity} onChange={handleOnChange}></input>
                                </div>
            </div>
        </div>
    )
}
export default InputColorText