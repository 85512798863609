import './input.css'

function Input({ 
    styles,
    type, 
    text, 
    name, 
    placeholder, 
    handleOnChange, 
    value, 
    multiple,
    pattern,
    status,

}) {
   
    return (
        <div className="formGroup ">
            <label htmlFor={name}>{text}:</label>
            <input className="input" type={type} name={name} id={name} placeholder={placeholder}
            onChange={handleOnChange} 
            value={value} 
            
            {...(multiple ? { multiple } : '')}
            />
        </div>

    )
}
export default Input