import api from '../../../utils/api'
import { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import './EditVcard.css'


//form
import Input from '../../../shared/components/form/input'
import Checkbox from '../../../shared/components/form/checkbox'

import Vcard from '../../VCard/VCard'
import InputColor from '../../../shared/components/form/inputColor'
import PubTemp0001 from '../../Templates/PublicTemplate/PubTemp0001/PubTemp0001'
import InputColorText from '../../../shared/components/form/inputColorText'

function EditVcard({ vcardData }) {


    const [previewlogo, setPreviewlogo] = useState()
    const [fileLogo, setFileLogo] = useState("Selecione um arquivo")
    const [filePerfil, setFilePerfil] = useState("Selecione um arquivo")
    const [fileBackground, setFileBackground] = useState("Selecione um arquivo")
    const [vcardinfo, setVcardinfo] = useState(vcardData || '')
    const [share, setShare] = useState(vcardData.share || '')
    const [viewstatus, setViewstatus] = useState(vcardData.viewStatus || '')
    const [token] = useState(localStorage.getItem('token') || '')
    const navigate = useNavigate()




    useEffect(() => {


    })


    function onFileChangelogo(e) {
        let fileName = e.target.files[0]
        setVcardinfo({ ...vcardinfo, [e.target.name]: e.target.files[0] })
        setFileLogo(fileName.name)

    }
    function onFileChangePerfil(e) {
        let fileName = e.target.files[0]
        console.log("haha")
        console.log(fileName)
        setFilePerfil(fileName.name)
        setVcardinfo({ ...vcardinfo, [e.target.name]: e.target.files[0] })

    }
    function onFileChangeBackground(e) {
        let fileName = e.target.files[0]
        setFileBackground(fileName.name)
        setVcardinfo({ ...vcardinfo, [e.target.name]: e.target.files[0] })

    }
    function handleChange(e) {

        setVcardinfo({ ...vcardinfo, [e.target.name]: e.target.value })


    }
    function handleChangeColor(e) {
        console.log(e.target.value)
        setVcardinfo({ ...vcardinfo, backgroundColorContainer: e.target.value })
    }
    function handleChangeShare(e) {
        let objVcard = vcardinfo
        objVcard.share = { ...objVcard.share, [e.target.name]: e.target.value }
        console.log('aqui share')
        console.log(objVcard)

        setVcardinfo(objVcard)
        setShare({ ...share, [e.target.name]: e.target.value })

    }
    const handleChangeViewStatus = (key, value) => {
        let objVcard = vcardinfo

        if (!value) {
            objVcard.viewStatus = { ...objVcard.viewStatus, [key]: true }
        } else if (value == false) {
            objVcard.viewStatus = { ...objVcard.viewStatus, [key]: true }
        } else if (value == true) {
            objVcard.viewStatus = { ...objVcard.viewStatus, [key]: false }
        }

        setVcardinfo(objVcard)
        setViewstatus(objVcard.viewStatus)
    }

    async function handleSubmitLogo(e) {
        e.preventDefault()

        const formData = new FormData()

        const logoFormData = await Object.keys(vcardinfo).forEach((key) =>
            formData.append(key, vcardinfo[key])
        )



        const data = await api.patch(`/vcard/edit/logo/${vcardinfo._id}`, formData, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            return response.data
        }).catch((err) => {
            return err.response.data
        })

        if (data.logoName) {

            setVcardinfo({ ...vcardinfo, logo: data.logoName })
            navigate('/')
        }



    }
    function submit(e){
        e.preventDefault()
        
       updateVcard(vcardinfo)
       
    }
    async function updateVcard(vcard1) {
        let msgType = 'success'
        
        
        const data = await api.patch(`vcard/edit/${vcard1._id}`, vcard1, {
            headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
        },
        }).then((response) => {
            
            return response.data
        }).catch((err) => {
            
            msgType= 'error'
            return err.response.data
        })
        /* setFlashMessage(data.message, msgType) */

    }
    async function handleSubmitPerfil(e) {
        e.preventDefault()

        const formData = new FormData()

        const logoFormData = await Object.keys(vcardinfo).forEach((key) =>
            formData.append(key, vcardinfo[key])
        )

        const data = await api.patch(`/vcard/edit/perfil/${vcardinfo._id}`, formData, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            return response.data
        }).catch((err) => {
            return err.response.data
        })

        if (data.perfilName) {

            setVcardinfo({ ...vcardinfo, perfilImage: data.perfilName })
        }

    }
    async function handleSubmitBackground(e) {
        e.preventDefault()

        const formData = new FormData()

        const logoFormData = await Object.keys(vcardinfo).forEach((key) =>
            formData.append(key, vcardinfo[key])
        )

        const data = await api.patch(`/vcard/edit/background/${vcardinfo._id}`, formData, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            return response.data
        }).catch((err) => {
            return err.response.data
        })

        if (data.backgroundName) {

            setVcardinfo({ ...vcardinfo, backgroundImage: data.backgroundName })
        }

    }




    return (
        <section>
            <div className='home-container'>
                <div className='home-card-box'>


                    <Vcard
                        vcardId={vcardinfo._id}
                        userName={vcardinfo.userName}
                        colorUserName={vcardinfo.colorUserName}
                        occupation={vcardinfo.occupation}
                        colorOccupation={vcardinfo.occupation}
                        template={vcardinfo.template}
                        colorLinks={vcardinfo.colorLinks}

                        cellPhoneNumber={vcardinfo.cellPhoneNumber}
                        phoneNumber={vcardinfo.phoneNumber}
                        whatsAppLink={vcardinfo.whatsAppLink}
                        email={vcardinfo.email}
                        facebook={vcardinfo.facebook}
                        instagram={vcardinfo.instagram}
                        linkedIn={vcardinfo.linkedIn}
                        site={vcardinfo.site}
                        local={vcardinfo.local}
                        shareTitle={share.shareTitle}
                        shareText={share.Text}
                        logo={vcardinfo.logo}
                        previewlogo={previewlogo}
                        perfilImage={vcardinfo.perfilImage}
                        backgroundImage={vcardinfo.backgroundImage}

                        backgroundColor={vcardinfo.backgroundColor}
                        backgroundColorContainerGrau={vcardinfo.backgroundColorContainerGrau}
                        backgroundColorContainer1={vcardinfo.backgroundColorContainer1}
                        backgroundColorContainerOpacity1={vcardinfo.backgroundColorContainerOpacity1}
                        backgroundColorContainer2={vcardinfo.backgroundColorContainer2}
                        backgroundColorContainerOpacity2={vcardinfo.backgroundColorContainerOpacity2}

                        backgroundColorCardGrau={vcardinfo.backgroundColorCardGrau}
                        backgroundColorCard1={vcardinfo.backgroundColorCard1}
                        backgroundColorCardOpacity1={vcardinfo.backgroundColorCardOpacity1}
                        backgroundColorCard2={vcardinfo.backgroundColorCard2}
                        backgroundColorCardOpacity2={vcardinfo.backgroundColorCardOpacity2}

                        backgroundColorLinksGrau={vcardinfo.backgroundColorLinksGrau}
                        backgroundColorLinks1={vcardinfo.backgroundColorLinks1}
                        backgroundColorLinksOpacity1={vcardinfo.backgroundColorLinksOpacity1}
                        backgroundColorLinks2={vcardinfo.backgroundColorLinks2}
                        backgroundColorLinksOpacity2={vcardinfo.backgroundColorLinksOpacity2}

                        linksColorText={vcardinfo.linksColorText}
                        linksColorTextOpacity={vcardinfo.linksColorTextOpacity}

                        color1Grau={vcardinfo.color1Grau}
                        color11={vcardinfo.color11}
                        color1Opacity1={vcardinfo.color1Opacity1}
                        color12={vcardinfo.color12}
                        color1Opacity2={vcardinfo.color1Opacity2}

                        color2Grau={vcardinfo.color2Grau}
                        color21={vcardinfo.color21}
                        color2Opacity1={vcardinfo.color2Opacity1}
                        color22={vcardinfo.color22}
                        color2Opacity2={vcardinfo.color2Opacity2}

                        color3={vcardinfo.color3}
                        color4={vcardinfo.color4}
                        color5={vcardinfo.color5}

                        userNameColor={vcardinfo.userNameColor}
                        userNameColorOpacity={vcardinfo.userNameColorOpacity}

                        occupationColor={vcardinfo.occupationColor}
                        occupationColorOpacity={vcardinfo.occupationColorOpacity}

                        viewWhatsApp={viewstatus.whatsAppLink}
                        viewCellPhoneNumber={viewstatus.cellPhoneNumber}
                        viewPhoneNumber={viewstatus.phoneNumber}
                        viewEmail={viewstatus.email}
                        viewFacebook={viewstatus.facebook}
                        viewInstagram={viewstatus.instagram}
                        viewLinkedIn={viewstatus.linkedIn}
                        viewLocal={viewstatus.local}
                        viewSite={viewstatus.site}
                        viewLogo={viewstatus.logo}
                        viewPerfil={viewstatus.perfil}
                        viewBackgroundImage={viewstatus.backgroundImage}
                    />




                </div>
                <div className='div-edit-card-box-right'>
                    <div className='home-edit-card-box'>
                        <div className='div-imagem-description'>
                            <div className='div-title'>Imagens</div>
                            <span>Ao enviar as imagens atualize a pagina para que elas possam ser visualizadas no cartão virtual</span>
                            <span>Para imagens com transparência use o formato de imagem PNG</span>


                            <form onSubmit={handleSubmitLogo}>
                                <div className='label-img'>Imagem da sua logo:</div>
                                <div className="div-box-input-img">
                                    <label htmlFor="logoUpload" > {fileLogo}</label>
                                    <input id="logoUpload" type='file' name='image' onChange={(e) => { onFileChangelogo(e) }}></input>

                                    <input type='submit' value="Enviar" />
                                </div>
                            </form>
                            <Checkbox
                                text="Exibir"
                                check={viewstatus.logo}
                                handleOnChange={() => { handleChangeViewStatus('logo', viewstatus.logo) }}
                            />
                            <form onSubmit={handleSubmitPerfil}>
                                <div className='label-img'>Imagem do seu perfil:</div>
                                <div className="div-box-input-img">
                                    <label htmlFor="perfilUpload" > {filePerfil}</label>
                                    <input id="perfilUpload" type='file' name='image' onChange={(e) => { onFileChangePerfil(e) }}></input>

                                    <input type='submit' value="Enviar" />
                                </div>
                            </form>
                            <Checkbox
                                text="Exibir"
                                check={viewstatus.perfil}
                                handleOnChange={() => { handleChangeViewStatus('perfil', viewstatus.perfil) }}
                            />
                            <form id='form-background' onSubmit={handleSubmitBackground}>
                                <div className='label-img'>Imagem de fundo:</div>
                                <div className="div-box-input-img">
                                    <label htmlFor="backgroundUpload" > {fileBackground}</label>
                                    <input id="backgroundUpload" type='file' name='image' onChange={(e) => { onFileChangeBackground(e) }}></input>

                                    <input form='form-background' type='submit' value="Enviar" />
                                </div>
                            </form>
                            <Checkbox
                                text="Exibir"
                                check={viewstatus.backgroundImage}
                                handleOnChange={() => { handleChangeViewStatus('backgroundImage', viewstatus.backgroundImage) }}
                            />
                            {/*  ---------------- CORES */}
                            <InputColor
                                title="Cor do fundo da página"
                                nameGrau={"backgroundColorContainerGrau"}
                                grau={vcardinfo.backgroundColorContainerGrau}
                                handleOnChange={handleChange}
                                nameColor1={"backgroundColorContainer1"}
                                color1={vcardinfo.backgroundColorContainer1}
                                opacity1={vcardinfo.backgroundColorContainerOpacity1}
                                nameOpacity1={"backgroundColorContainerOpacity1"}
                                nameColor2={"backgroundColorContainer2"}
                                color2={vcardinfo.backgroundColorContainer2}
                                opacity2={vcardinfo.backgroundColorContainerOpacity2}
                                nameOpacity2={"backgroundColorContainerOpacity2"}
                            />

                            <InputColor
                                title="Cor do fundo do cartão"
                                nameGrau={"backgroundColorCardGrau"}
                                grau={vcardinfo.backgroundColorCardGrau}
                                handleOnChange={handleChange}
                                nameColor1={"backgroundColorCard1"}
                                color1={vcardinfo.backgroundColorCard1}
                                opacity1={vcardinfo.backgroundColorCardOpacity1}
                                nameOpacity1={"backgroundColorCardOpacity1"}
                                nameColor2={"backgroundColorCard2"}
                                color2={vcardinfo.backgroundColorCard2}
                                opacity2={vcardinfo.backgroundColorCardOpacity2}
                                nameOpacity2={"backgroundColorCardOpacity2"}
                            />

                            <InputColor
                                title="Cor de fundo dos Links"
                                nameGrau={"backgroundColorLinksGrau"}
                                grau={vcardinfo.backgroundColorLinksGrau}
                                handleOnChange={handleChange}
                                nameColor1={"backgroundColorLinks1"}
                                color1={vcardinfo.backgroundColorLinks1}
                                opacity1={vcardinfo.backgroundColorLinksOpacity1}
                                nameOpacity1={"backgroundColorLinksOpacity1"}
                                nameColor2={"backgroundColorLinks2"}
                                color2={vcardinfo.backgroundColorLinks2}
                                opacity2={vcardinfo.backgroundColorLinksOpacity2}
                                nameOpacity2={"backgroundColorLinksOpacity2"}
                            />
                            <InputColorText
                                title="Cor de texto dos Links"
                                nameColor="linksColorText"
                                color={vcardinfo.linksColorText}
                                nameOpacity="linksColorTextOpacity"
                                opacity={vcardinfo.linksColorTextOpacity}
                                handleOnChange={handleChange}
                            />
                            <InputColor
                                title="Cor 1"
                                nameGrau={"color1Grau"}
                                grau={vcardinfo.color1Grau}
                                handleOnChange={handleChange}
                                nameColor1={"color11"}
                                color1={vcardinfo.color11}
                                opacity1={vcardinfo.color1Opacity1}
                                nameOpacity1={"color1Opacity1"}
                                nameColor2={"color12"}
                                color2={vcardinfo.color12}
                                opacity2={vcardinfo.color1Opacity2}
                                nameOpacity2={"color1Opacity2"}
                            />
                            <InputColor
                                title="Cor 2"
                                nameGrau={"color2Grau"}
                                grau={vcardinfo.color2Grau}
                                handleOnChange={handleChange}
                                nameColor1={"color21"}
                                color1={vcardinfo.color21}
                                opacity1={vcardinfo.color2Opacity1}
                                nameOpacity1={"color2Opacity1"}
                                nameColor2={"color22"}
                                color2={vcardinfo.color22}
                                opacity2={vcardinfo.color2Opacity2}
                                nameOpacity2={"color2Opacity2"}
                            />

                            {/*  ---------------- FIM CORES */}

                        </div>
                        <form id='form-edit-vcard' onSubmit={submit}>
                            <Input
                                text="Nome"
                                type="text"
                                name="userName"
                                placeholder="digite seu nome"
                                handleOnChange={handleChange}
                                value={vcardinfo.userName || ''}
                            />
                            <InputColorText
                                nameColor="userNameColor"
                                color={vcardinfo.userNameColor}
                                nameOpacity="userNameColorOpacity"
                                opacity={vcardinfo.userNameColorOpacity}
                                handleOnChange={handleChange}

                            />

                            <Input
                                text="Profissão"
                                type="text"
                                name="occupation"
                                placeholder="digite sua profissão"
                                handleOnChange={handleChange}
                                value={vcardinfo.occupation || ''}
                            />
                            <InputColorText
                                nameColor="occupationColor"
                                color={vcardinfo.occupationColor}
                                nameOpacity="occupationColorOpacity"
                                opacity={vcardinfo.occupationColorOpacity}
                                handleOnChange={handleChange}

                            />
                            <div className="formGroup ">
                                <label nameFor="template">Escolha o modelo do cartão</label>
                                <select className="input" name="template" id='template'>
                                    <option value='PubTemp0001'>Modelo 1</option>
                                </select>
                            </div>
                            <Input
                                text="Telefone Celular"
                                type="tel"

                                name="cellPhoneNumber"
                                placeholder="digite seu celular (xx)xxxx-xxxx"
                                handleOnChange={handleChange}
                                value={vcardinfo.cellPhoneNumber || ''}
                            />
                            <Checkbox
                                text="Exibir"
                                check={viewstatus.cellPhoneNumber}
                                handleOnChange={() => { handleChangeViewStatus('cellPhoneNumber', viewstatus.cellPhoneNumber) }}
                            />
                            <Input
                                text="Telefone fixo"
                                type="tel"
                                name="phoneNumber"
                                placeholder="digite seu telefone"
                                handleOnChange={handleChange}
                                value={vcardinfo.phoneNumber || ''}
                            />
                            <Checkbox
                                text="Exibir"
                                check={viewstatus.phoneNumber}
                                handleOnChange={() => { handleChangeViewStatus('phoneNumber', viewstatus.phoneNumber) }}
                            />
                            <Input
                                text="Link do WhatsApp"
                                type="text"
                                name="whatsAppLink"
                                placeholder="digite o link para o seu whatts App"
                                handleOnChange={handleChange}
                                value={vcardinfo.whatsAppLink || ''}
                            />
                            <Checkbox
                                text="Exibir"
                                check={viewstatus.whatsAppLink}
                                handleOnChange={() => { handleChangeViewStatus('whatsAppLink', viewstatus.whatsAppLink) }}
                            />
                            <Input
                                text="E-mail"
                                type="email"
                                name="email"
                                placeholder="digite seu email"
                                handleOnChange={handleChange}
                                value={vcardinfo.email || ''}
                            />
                            <Checkbox
                                text="Exibir"
                                check={viewstatus.email}
                                handleOnChange={() => { handleChangeViewStatus('email', viewstatus.email) }}
                            />
                            <Input
                                text="Link do Facebook"
                                type="text"
                                name="facebook"
                                placeholder="digite o link para o seu facebook"
                                handleOnChange={handleChange}
                                value={vcardinfo.facebook || ''}
                            />
                            <Checkbox
                                text="Exibir"
                                check={viewstatus.facebook}
                                handleOnChange={() => { handleChangeViewStatus('facebook', viewstatus.facebook) }}
                            />
                            <Input
                                text="Link do Instagram"
                                type="text"
                                name="instagram"
                                placeholder="digite o link para o seu Instagram"
                                handleOnChange={handleChange}
                                value={vcardinfo.instagram || ''}
                            />
                            <Checkbox
                                text="Exibir"
                                check={viewstatus.instagram}
                                handleOnChange={() => { handleChangeViewStatus('instagram', viewstatus.instagram) }}
                            />
                            <Input
                                text="Link do Linked in"
                                type="text"
                                name="linkedIn"
                                placeholder="digite o link para o seu Linked In"
                                handleOnChange={handleChange}
                                value={vcardinfo.linkedIn || ''}
                            />
                            <Checkbox
                                text="Exibir"
                                check={viewstatus.linkedIn}
                                handleOnChange={() => { handleChangeViewStatus('linkedIn', viewstatus.linkedIn) }}
                            />
                            <Input
                                text="Site"
                                type="text"
                                name="site"
                                placeholder="digite o link para o seu site"
                                handleOnChange={handleChange}
                                value={vcardinfo.site || ''}
                            />
                            <Checkbox
                                text="Exibir"
                                check={viewstatus.site}
                                handleOnChange={() => { handleChangeViewStatus('site', viewstatus.site) }}
                            />
                            <Input
                                text="Localização"
                                type="text"
                                name="local"
                                placeholder="digite o link para o local do google maps"
                                handleOnChange={handleChange}
                                value={vcardinfo.local || ''}
                            />
                            <Checkbox
                                text="Exibir"
                                check={viewstatus.local}
                                handleOnChange={() => { handleChangeViewStatus('local', viewstatus.local) }}
                            />

                            <div>Informaçoes para compartilhamento</div>
                            <Input
                                text="Titulo do link de compartilhamento"
                                type="text"
                                name="title"
                                placeholder="digite o titulo que aparecerá no compartilhamento"
                                handleOnChange={handleChangeShare}
                                value={share.title || ''}
                            />
                            <Input
                                text="Texto do link de compartilhamento"
                                type="text"
                                name="text"
                                placeholder="digite o texto que aparecerá no compartilhamento"
                                handleOnChange={handleChangeShare}
                                value={share.text || ''}
                            />



                        </form>
                    </div>
                    <div className='div-save-button'>
                        <input form='form-edit-vcard' type='submit' value="Salvar" />
                    </div>
                </div>
            </div>
        </section>
    )
}
export default EditVcard