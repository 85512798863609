

const example = {
    backgroundColorCard1: "#ffffff",
    backgroundColorCard2: "#ffffff",
    backgroundColorCardGrau: "0",
    backgroundColorCardOpacity1: "100",
    backgroundColorCardOpacity2: "100",
    backgroundColorContainer1: "#ffffff",
    backgroundColorContainer2: "#ffffff",
    backgroundColorContainerGrau: "0",
    backgroundColorContainerOpacity1: "100",
    backgroundColorContainerOpacity2: "100",
    backgroundColorLinks1: "#ffffff",
    backgroundColorLinks2: "#ffffff",
    backgroundColorLinksGrau: "0",
    backgroundColorLinksOpacity1: "62",
    backgroundColorLinksOpacity2: "62",
    backgroundImage: "backgroundImage01.jpg",
    
    color1Grau: "294",
    color1Opacity1: "71",
    color1Opacity2: "59",
    color2Grau: "294",
    color2Opacity1: "71",
    color2Opacity2: "59",
    color11: "#6f6ab9",
    color12: "#000000",
    color21: "#6f6ab9",
    color22: "#000000",
    
    
    linksColorText: "#000000",
    linksColorTextOpacity: "100",
    logo: "logo01.png",
    occupation: "Sua Profissão",
    occupationColor: "#ffffff",
    occupationColorOpacity: "100",
    perfilImage: "perfil01.jpg",
    phoneNumber: "",
    share: {
        text: "",
        title: "",
    },
    template: "PubTemp0001",
    
    
    userName: "Seu Nome",
    userNameColor: "#ffffff",
    userNameColorOpacity: "100",
    viewStatus: {
        backgroundImage: true,
        cellPhoneNumber: true,
        email: true,
        facebook: true,
        instagram: true,
        linkedIn: true,
        local: true,
        logo: true,
        perfil: true,
        phoneNumber: true,
        site: true,
        whatsAppLink: true,
    },
    whattsAppLink: "",


}

export default example