import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { useState, useEffect } from 'react'
import api from '../../utils/api'
import './navbar.css'

/* context */
import { Context } from '../../context/UserContext'

function Navbar() {

  const { authenticated, logout, userName } = useContext(Context)
  const [access, setAccess] = useState({})
  const [accessClient, setAccessClient] = useState({})
  console.log(userName)
  const teste = () => {

    console.log(userName)

  }
  useEffect(() => {
    const token = localStorage.getItem('token')

    /* api.get('/user/get/permission', {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((response) => {
      console.log('teste')
      const user = response.data.user
      console.log(user)


      let permission = user.permission
      console.log(permission)
      setAccessClient(permission[0])
      console.log('use  effect nav')

    }) */

  }, [])






  if (authenticated) {
    return (

      <nav className="navbar">

        <div>
          <div>
            <Link to="/"> Meu cartão</Link>

            <div className="dropdown">
              <li className="dropbtn">Admistrador

              </li>
              <div className="dropdown-content">
                <Link to="/user/list"> Usuários</Link>



              </div>
            </div>







          </div>

          <div className='perfil'>
            {authenticated ? (
              <>
                <li>Olá {userName}</li>
                <li onClick={logout}>Sair</li>
              </>
            ) : (<Link to="/login"> Login</Link>)

            }
          </div>

        </div>

      </nav>


    )
  } else {
    return (<div></div>)
  }

}

export default Navbar