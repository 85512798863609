import api from '../utils/api'

import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useFlashMessage from './useFlashMessage'




export default function useAuth() {
    const [authenticated,  setAuthenticated] = useState(false)
    const [userName, setUserName] = useState()
    const [access,  setAccess] = useState({})
    const [permissionClient, setPermissionClient] = useState({})
    const [permissionProvider, setPermissionProvider] = useState({})
    const { setFlashMessage } = useFlashMessage()
    const navigate = useNavigate()
    

    useEffect(() => {
        const token = localStorage.getItem('token')

        if (token) {
            api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`
            setAuthenticated(true)
            
            
        }
        
        console.log('use  effect')
        console.log(permissionClient)
    }, [])

    /* FUNÇÃO DE REGISTRO DE NOVOS USUARIOS */
    async function register(user) {

        let msgText = 'Cadastro realizado com sucesso !'
        let msgType = 'success'
        try {
            const data = await api.post('/user/register', user).then((response) => {
                return response.data
            })
          
          navigate('/user/list')
            
        } catch(error){

           msgText = error.response.data.message
           msgType = 'error'
            
        }

        setFlashMessage(msgText, msgType)

    }

    /* FUNÇÃO DE LOGIN */
    async function login(user) {
        let msgText = 'Login realizado com sucesso !'
        let msgType = 'success'

        try {
            console.log(user)
            const data = await api.post('/user/login', user).then((response) => {
                console.log(response.data)
                return response.data
            })
            console.log('login')

            await authUser(data)
            
        } catch (error) {
            console.log('erro de login')
           /*  msgText = error.response.data.message
            msgType = 'error' */
            
        }
        /* setFlashMessage(msgText, msgType) */

    }

    /* FUNÇÃO DE AUTENTICAR */
    async function authUser(data){
        console.log(data)
        

       setAuthenticated(true)
      
      /*  let permission = data.permission[0] */
       
       /* setPermissionClient(data.permission[0]) */
       setUserName(data.userName)
       console.log('Nome do usuario 2')
       console.log(data)
       
       

       setAccess(data.permission)
       console.log(authenticated)

        localStorage.setItem('token', JSON.stringify(data.token))
        /* console.log(data.token) */
        
        navigate('/')

        

    }
   

    async function logout(){
        const msgText = 'Logout realizado com sucesso !'
        const msgType = 'success'

        setAuthenticated(false)
        localStorage.removeItem('token')
        api.defaults.headers.Authorization = undefined

        navigate('/login')

        setFlashMessage(msgText, msgType)
    }   

    return { authenticated, access, permissionClient, userName,  register, login, logout}
}