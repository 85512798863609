import './inputColor.css'

function InputColor({
    title,
    nameGrau,
    grau,
    handleOnChange,
    nameColor1,
    color1,
    opacity1,
    nameOpacity1,
    nameColor2,
    color2,
    nameOpacity2,
    opacity2,


}) {

    return (
        <div>
            <label className='title'>{title}</label>
                            <div className='div-color'>

                                <div className='div-gradient-angle'>
                                    <label for={nameGrau}>Angulo do gradiente:</label>
                                    <div className='gradient-angle'>

                                        <input className="input-color-opacity-text" name={nameGrau} type='text' value={grau} onChange={handleOnChange} />
                                        <input type="range" id={nameGrau} name={nameGrau} min="0" max="360" value={grau} onChange={handleOnChange}></input>
                                    </div>
                                </div>
                                <div className='box-cor'>
                                    <div>

                                        <input type="color" id={nameColor1} name={nameColor1} value={color1} onChange={handleOnChange} />
                                    </div>



                                    <label for={nameOpacity1}>Opacidade: </label>
                                    <input className="input-color-opacity-text" name={nameOpacity1} type='text' value={opacity1} onChange={handleOnChange} />

                                    <input type="range" id={nameOpacity1} name={nameOpacity1} min="0" max="100" value={opacity1} onChange={handleOnChange}></input>
                                </div>

                                <div className='box-cor'> 
                                    <div>
                                        <input type="color" id={nameColor2} name={nameColor2} value={color2} onChange={handleOnChange} />
                                    </div>
                                    <label for={nameOpacity2}>Opacidade: </label>
                                    <input className="input-color-opacity-text" name={nameOpacity2} type='text' value={opacity2} onChange={handleOnChange} />

                                    <input type="range" id={nameOpacity2} name={nameOpacity2} min="0" max="100" value={opacity2} onChange={handleOnChange}></input>


                                </div>
                            </div>
        </div>

    )
}
export default InputColor