import { useEffect, useState } from 'react'

import './PubTemp0001.css'


import iconShare from './img/iconShare.png'
import iconWhatsApp from './img/iconWhatsApp.png'
import iconEmail from './img/iconEmail.png'
import iconCel from './img/iconCel.png'
import iconPhone from './img/iconPhone.png'
import iconSite from './img/iconSite.png'
import iconFacebook from './img/iconFacebook.png'
import iconInstagram from './img/iconInstagram.png'
import iconLinkedIn from './img/iconLinkedIn.png'
import iconLocal from './img/iconLocal.png'
import serverURL from '../../../../utils/serverURL'

const hexToRgba = (hex) => {
    let c;
    let rgba = "255,255,255"
    if (hex) {
        c = hex.substring(1).split('');
        c = c[0] + c[1] + c[2] + c[3] + c[4] + c[5]

        c = '0x' + c


        let r = c >> 16 & 0xFF
        let g = c >> 8 & 0xFF
        let b = c & 0xFF
        rgba = `${r}, ${g}, ${b}`
    }

    return rgba
}


function PubTemp0001({ vcardId, userName, occupation, template, cellPhoneNumber, viewCellPhoneNumber, phoneNumber,
    viewPhoneNumber, whatsAppLink, viewWhatsApp, email, viewEmail, facebook, viewFacebook, instagram, viewInstagram, linkedIn,
    viewLinkedIn, site, viewSite, local,  previewLogo, viewLocal, shareTitle, shareText, logo, viewLogo, perfilImage, viewPerfil, backgroundImage, viewBackgroundImage, backgroundColor, backgroundColorContainer1,
    backgroundColorContainerGrau, backgroundColorContainerOpacity1, backgroundColorContainer2, backgroundColorContainerOpacity2,
    backgroundColorCard1, backgroundColorCardGrau, backgroundColorCardOpacity1, backgroundColorCard2, backgroundColorCardOpacity2,
    backgroundColorLinksGrau, backgroundColorLinks1, backgroundColorLinksOpacity1, backgroundColorLinks2, backgroundColorLinksOpacity2,
    linksColorText, linksColorTextOpacity,
    color1Grau,color11,color1Opacity1,color12,color1Opacity2,
    color2Grau,color21,color2Opacity1,color22,color2Opacity2,
    color3, color4, color5 ,
    userNameColor,  userNameColorOpacity, occupationColor, occupationColorOpacity,

}) {



    const teste = "10"

    useEffect(() => {


    })

    //função compartilhar
    function share() {
        if (navigator.share !== undefined) {
            navigator.share({
                title: shareTitle,
                text: shareText,
                url: `${serverURL}/vcard/${vcardId}`,

            })
                .then(() => console.log("compartilhado com sucesso"))
                .catch((error) => console.log('Error sharing', error));
        }
    }

    

    return (
        <>

            <body className='body-vcard' style={{ background: ` linear-gradient(${backgroundColorContainerGrau || "0"}deg, rgba( ${hexToRgba(backgroundColorContainer1)}, ${backgroundColorContainerOpacity1 || "100"}%) 0%, rgba(${hexToRgba(backgroundColorContainer2)},${backgroundColorContainerOpacity2 || "100"}%) 100%)` }}>
                <div className="container" style={{background: ` linear-gradient(${backgroundColorCardGrau || "0"}deg, rgba( ${hexToRgba(backgroundColorCard1)}, ${backgroundColorCardOpacity1 || "100"}%) 0%, rgba(${hexToRgba(backgroundColorCard2)},${backgroundColorCardOpacity2 || "100"}%) 100%)`}}>
                    <div className="container-vcard"

                        style={ viewBackgroundImage? (
                            {
                            backgroundImage: ((backgroundImage) ?
                                (
                                    `url(${serverURL}/userImages/background/${backgroundImage}`
                                ) : (
                                    ""
                                ))
                        }
                        ):(
                            {
                                backgroundImage: `none`
                            }
                        )
                    }>
                        <div className="box-triangle"  >
                            <div className="triangle" style={{ background: ` linear-gradient(to bottom right, rgba( ${hexToRgba(color21)}, ${color2Opacity1 || "100"}%) 0%, rgba( ${hexToRgba(color22)}, ${color2Opacity2 || "50"}%) 50%,  rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%)` }} >
                                <div className="logo" style={ viewLogo ? 
                                (
                                    {
                                    backgroundImage: ((logo) ?
                                        (
                                            `url(${serverURL}/userImages/logo/${logo}`
                                        ) : (
                                            ""
                                        ))
                                }
                                ):({backgroundImage: `none`})
                                }
                                /* style={{backgroundImage: (logo) &&  (  {}`url(${serverURL}/userImages/logo/${logo})`  )}} */

                                >

                                </div>
                                {/*        {(logo)&& (
                                <img src={`${serverURL}/userImages/logo/${logo}` } 
                                alt={logo}
                                />

                            )} */}
                            </div>
                        </div>
                        <section className="box-foto-perfil">
                            {viewPerfil && (
                                <div className="foto-perfil" style={{
                                    backgroundImage: ((logo) ?
                                        (
                                            `url(${serverURL}/userImages/perfil/${perfilImage}`
                                        ) : (
                                            ""
                                        ))
                                        
                                }}
                                ></div>
                            )}
                            </section>
                        <div className="card" style={viewPerfil ? ( {margin: '-100px 0 0 0 ', background: ` linear-gradient(${color1Grau || "0"}deg, rgba( ${hexToRgba(color11)}, ${color1Opacity1 || "100"}%) 0%, rgba(${hexToRgba(color12)},${color1Opacity2 || "100"}%) 100%)` }) : ({margin: '80px 0 0 0' , background: ` linear-gradient(${color1Grau || "0"}deg, rgba( ${hexToRgba(color11)}, ${color1Opacity1 || "100"}%) 0%, rgba(${hexToRgba(color12)},${color1Opacity2 || "100"}%) 100%)` })}>
                                             {/* style={viewPerfil ? ( {margin: '-100px 0 0 0 ', background: ` linear-gradient(${color1Grau || "0"}deg, rgba( ${hexToRgba(color11)}, ${color1Opacity1 || "100"}%) 0%, rgba(${hexToRgba(color12)},${color1Opacity2 || "100"}%) 100%)` }) : ({margin: '20px 0 0 0' , background: ` linear-gradient(${color1Grau || "0"}deg, rgba( ${hexToRgba(color11)}, ${color1Opacity1 || "100"}%) 0%, rgba(${hexToRgba(color12)},${color1Opacity2 || "100"}%) 100%)` })} */}
                            <section className="box-text"  style={viewPerfil ? ( {margin: '100px 0 0 0 '}) : ({margin: '20px 0 0 0'})} >
                                <div className="name" style={{color:`rgba(${hexToRgba(userNameColor)}, ${userNameColorOpacity || "100"}%)`}}> {userName}</div>
                                <div className="box-occupation">
                                    <div className="line" style={{background:`rgba(${hexToRgba(occupationColor)}, ${occupationColorOpacity || "100"}%)`}}></div>
                                    <div className="occupation" style={{color:`rgba(${hexToRgba(occupationColor)}, ${occupationColorOpacity || "100"}%)`}}>{occupation}</div>
                                    <div className="line" style={{background:`rgba(${hexToRgba(occupationColor)}, ${occupationColorOpacity || "100"}%)`}}></div>
                                </div>
                                <div className="option">
                                    <div className="box-rounded-link" style={{ background: ` linear-gradient(${backgroundColorLinksGrau || "0"}deg, rgba( ${hexToRgba(backgroundColorLinks1)}, ${backgroundColorLinksOpacity1 || "100"}%) 0%, rgba(${hexToRgba(backgroundColorLinks2)},${backgroundColorLinksOpacity2 || "100"}%) 100%)` }}>
                                        <a href="javascript:void(0)" onClick={() => { share() }}>
                                            <img className='box-share' src={iconShare} />
                                        </a>
                                    </div>
                                </div>
                            </section>

                            <section className="box-all-button">
                                {viewWhatsApp && (
                                    <div className="box-button" style={{ background: ` linear-gradient(${backgroundColorLinksGrau || "0"}deg, rgba( ${hexToRgba(backgroundColorLinks1)}, ${backgroundColorLinksOpacity1 || "100"}%) 0%, rgba(${hexToRgba(backgroundColorLinks2)},${backgroundColorLinksOpacity2 || "100"}%) 100%)` }}>
                                        <a className="box-link" href={whatsAppLink} target="_blank">
                                            <img className='button-img' src={iconWhatsApp} />
                                            <p className="button-img-description" style={{color:`rgba( ${hexToRgba(linksColorText)}, ${linksColorTextOpacity || '100'}%)`}}>WhatsApp</p>
                                            <div className="button-img"></div>
                                        </a>

                                    </div>
                                )}
                                {viewCellPhoneNumber && (
                                    <div className="box-button" style={{ background: ` linear-gradient(${backgroundColorLinksGrau || "0"}deg, rgba( ${hexToRgba(backgroundColorLinks1)}, ${backgroundColorLinksOpacity1 || "100"}%) 0%, rgba(${hexToRgba(backgroundColorLinks2)},${backgroundColorLinksOpacity2 || "100"}%) 100%)` }}>
                                        <a className="box-link" href={`tel:${cellPhoneNumber}`}>
                                            <img className='button-img' src={iconCel} />
                                            <p className="button-img-description" style={{color:`rgba( ${hexToRgba(linksColorText)}, ${linksColorTextOpacity || '100'}%)`}}>Celular</p>
                                            <div className="button-img"></div>
                                        </a>
                                    </div>
                                )}
                                {viewPhoneNumber && (
                                    <div className="box-button" style={{ background: ` linear-gradient(${backgroundColorLinksGrau || "0"}deg, rgba( ${hexToRgba(backgroundColorLinks1)}, ${backgroundColorLinksOpacity1 || "100"}%) 0%, rgba(${hexToRgba(backgroundColorLinks2)},${backgroundColorLinksOpacity2 || "100"}%) 100%)` }}>
                                        <a className="box-link" href={`tel:${phoneNumber}`}>
                                            <img className='button-img' src={iconPhone} />
                                            <p className="button-img-description" style={{color:`rgba( ${hexToRgba(linksColorText)}, ${linksColorTextOpacity || '100'}%)`}}>Telefone Fixo</p>
                                            <div className="button-img"></div>
                                        </a>
                                    </div>
                                )}
                                {viewEmail && (
                                    <div className="box-button" style={{ background: ` linear-gradient(${backgroundColorLinksGrau || "0"}deg, rgba( ${hexToRgba(backgroundColorLinks1)}, ${backgroundColorLinksOpacity1 || "100"}%) 0%, rgba(${hexToRgba(backgroundColorLinks2)},${backgroundColorLinksOpacity2 || "100"}%) 100%)` }}>
                                        <a className="box-link" href={`mailto:${email}`}>
                                            <img className='button-img' src={iconEmail} />
                                            <p className="button-img-description" style={{color:`rgba( ${hexToRgba(linksColorText)}, ${linksColorTextOpacity || '100'}%)`}}>E-mail</p>
                                            <div className="button-img"></div>
                                        </a>
                                    </div>
                                )}
                                {viewFacebook && (
                                    <div className="box-button" style={{ background: ` linear-gradient(${backgroundColorLinksGrau || "0"}deg, rgba( ${hexToRgba(backgroundColorLinks1)}, ${backgroundColorLinksOpacity1 || "100"}%) 0%, rgba(${hexToRgba(backgroundColorLinks2)},${backgroundColorLinksOpacity2 || "100"}%) 100%)` }}>
                                        <a className="box-link" href={`${facebook}`}>
                                            <img className='button-img' src={iconFacebook} />
                                            <p className="button-img-description" style={{color:`rgba( ${hexToRgba(linksColorText)}, ${linksColorTextOpacity || '100'}%)`}}>Facebook</p>
                                            <div className="button-img"></div>
                                        </a>
                                    </div>
                                )}
                                {viewInstagram && (
                                    <div className="box-button" style={{ background: ` linear-gradient(${backgroundColorLinksGrau || "0"}deg, rgba( ${hexToRgba(backgroundColorLinks1)}, ${backgroundColorLinksOpacity1 || "100"}%) 0%, rgba(${hexToRgba(backgroundColorLinks2)},${backgroundColorLinksOpacity2 || "100"}%) 100%)` }}>
                                        <a className="box-link" href={`${instagram}`}>
                                            <img className='button-img' src={iconInstagram} />
                                            <p className="button-img-description" style={{color:`rgba( ${hexToRgba(linksColorText)}, ${linksColorTextOpacity || '100'}%)`}}>Instagram</p>
                                            <div className="button-img"></div>
                                        </a>
                                    </div>
                                )}
                                {viewLinkedIn && (
                                    <div className="box-button" style={{ background: ` linear-gradient(${backgroundColorLinksGrau || "0"}deg, rgba( ${hexToRgba(backgroundColorLinks1)}, ${backgroundColorLinksOpacity1 || "100"}%) 0%, rgba(${hexToRgba(backgroundColorLinks2)},${backgroundColorLinksOpacity2 || "100"}%) 100%)` }}>
                                        <a className="box-link" href={`${linkedIn}`}>
                                            <img className='button-img' src={iconLinkedIn} />
                                            <p className="button-img-description"style={{color:`rgba( ${hexToRgba(linksColorText)}, ${linksColorTextOpacity || '100'}%)`}}>Linked In</p>
                                            <div className="button-img"></div>
                                        </a>
                                    </div>
                                )}
                                {viewSite && (
                                    <div className="box-button" style={{ background: ` linear-gradient(${backgroundColorLinksGrau || "0"}deg, rgba( ${hexToRgba(backgroundColorLinks1)}, ${backgroundColorLinksOpacity1 || "100"}%) 0%, rgba(${hexToRgba(backgroundColorLinks2)},${backgroundColorLinksOpacity2 || "100"}%) 100%)` }}>
                                        <a className="box-link" href={`${site}`}>
                                            <img className='button-img' src={iconSite} />
                                            <p className="button-img-description"style={{color:`rgba( ${hexToRgba(linksColorText)}, ${linksColorTextOpacity || '100'}%)`}}>Site</p>
                                            <div className="button-img"></div>
                                        </a>
                                    </div>
                                )}
                                {viewLocal && (
                                    <div className="box-button" style={{ background: ` linear-gradient(${backgroundColorLinksGrau || "0"}deg, rgba( ${hexToRgba(backgroundColorLinks1)}, ${backgroundColorLinksOpacity1 || "100"}%) 0%, rgba(${hexToRgba(backgroundColorLinks2)},${backgroundColorLinksOpacity2 || "100"}%) 100%)` }}>
                                        <a className="box-link" href={`${local}`}>
                                            <img className='button-img' src={iconLocal} />
                                            <p className="button-img-description"style={{color:`rgba( ${hexToRgba(linksColorText)}, ${linksColorTextOpacity || '100'}%)`}}>Local</p>
                                            <div className="button-img"></div>
                                        </a>
                                    </div>
                                )}
                            </section>
                        </div>

                    </div>
                </div>

            </body>

        </>

    )
}
export default PubTemp0001