import { createContext } from "react"

import useAuth from "../hooks/useAuth"

const Context = createContext()

function UserProvider({ children}) {
    const { authenticated, access, userName , register, login, logout } = useAuth()

    return <Context.Provider value={{ authenticated, access, userName, register, login, logout }}>{children}</Context.Provider>
}

export {Context, UserProvider}