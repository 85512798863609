import './container.css'

function Container({children}) {

    return(
        <main className="container-box">{children}</main>

    )
}

export default Container