import api from '../../utils/api'

import { useState, useEffect } from "react"


import UserVcard from './components/UserVcard'
import EditVcard from './components/EditVcard'
//form
import Input from '../../shared/components/form/input'

import './Home.css'

import { useNavigate } from 'react-router-dom'


function Home() {
    const [ vcard, setVcard] = useState({})
    const [token] = useState(localStorage.getItem('token') || '')
    const navigate = useNavigate()
   
    /* console.log(userVcard) */
    useEffect(() => {
        api.get('/vcard/getVcard', {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        })
            .then((response) => {
                if(response.data.vcard){
                    setVcard(response.data.vcard)
                }else{
                    navigate('/createVcard')
                }
                /* setVcard(response.data.vcard) */
            })

    }, [token])

    

    return (
        <section>
            <div >
                {vcard.userId &&(
                    <EditVcard vcardData={vcard}/>
                )}
                

            </div>
        </section>
    )
}

export default Home