import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css';

//components
import Navbar from "./shared/navbar/navbar";
import Container from './shared/container/container';


/* private pages */
import PrivateRoute from './shared/privateRoute/PrivateRoute';


//pages
import Login from "./pages/auth/pages/Login"
import Register from "./pages/auth/pages/Register"
import Home from './pages/Home/Home'
import VCard from './pages/VCard/VCard';
import ChooseVcard from './pages/VCard/ChooseVcard';
import CreateVcard from './pages/CreateVcard/CreateVcard';

/* context */
import { UserProvider } from './context/UserContext';

function App() {
  return (
    <Router>
      <UserProvider>
        <Navbar />
        
          <Routes>
            <Route path="/login" exact element={<Login />} />
            <Route path="/register" exact element={<Register/>} />

            <Route element={<PrivateRoute />}>
              <Route path='/' exact element={<Home />} />
              <Route path='/home' exact element={<Home />} />
              <Route path='/createVcard' exact element={<CreateVcard />} />

            </Route>
          </Routes>


        
      </UserProvider>
    </Router>
  );
}

export default App;
