import './checkbox.css'

function Checkbox({
    text,
    name,
    handleOnChange,
    value,
    check,


}) {
    let checkValue = "no-check"
    
    if (check == true) {
        checkValue = "check"
        
    }

    const checkMark = () => {
        if (check == true) {
            return (
                <>
                    <div className='check-sinal-part1'></div><div className='check-sinal-part2'></div>
                </>
            )
        }else{
            return <>
            </>
        }
        return
    }


    return (
        <div className="checkbox-div">
            <div className={`checkbox-box ${checkValue}`} onClick={handleOnChange} > <div className='checkbox-interbox'> {checkMark()}</div></div>
            <label htmlFor={name}>{text}</label><br></br>

        </div>

    )
}
export default Checkbox