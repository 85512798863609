import dataUser from '../../bd/dataUser'
import PubTemp0001 from '../Templates/PublicTemplate/PubTemp0001/PubTemp0001';
import { useEffect, useState } from 'react'

function VCard({ vcardId, userName, occupation, template, cellPhoneNumber, viewCellPhoneNumber, phoneNumber, 
    viewPhoneNumber, whatsAppLink, viewWhatsApp, email, viewEmail, facebook, viewFacebook, instagram, viewInstagram,
     linkedIn, viewLinkedIn, site, viewSite, local, viewLocal, shareTitle, shareText, logo, viewLogo, perfilImage, viewPerfil, backgroundImage, viewBackgroundImage, backgroundColor, backgroundColorContainer1, backgroundColorContainer2, backgroundColorContainerOpacity2,
     backgroundColorContainerGrau, backgroundColorContainerOpacity1,
     backgroundColorCard1, backgroundColorCard2, backgroundColorCardOpacity2,
     backgroundColorCardGrau, backgroundColorCardOpacity1,
     backgroundColorLinksGrau, backgroundColorLinks1, backgroundColorLinksOpacity1, backgroundColorLinks2, backgroundColorLinksOpacity2,
     linksColorText, linksColorTextOpacity,
     color1Grau,color11,color1Opacity1,color12,color1Opacity2,
     color2Grau,color21,color2Opacity1,color22,color2Opacity2,
     color3, color4, color5,
    userNameColor,  userNameColorOpacity, occupationColor, occupationColorOpacity,
    }) {

const [teste, setTeste] = useState(logo)


    useEffect(() => {
console.log('atualizou')
setTeste(logo)
    },[])

    const selectTemplate = () => {
        switch (template) {
            case 'PubTemp0001':
                return (
                    <div>

                        <PubTemp0001
                            userName={userName}
                            occupation={occupation}
                            cellPhoneNumber={cellPhoneNumber}
                            phoneNumber={phoneNumber}
                            whatsAppLink={whatsAppLink}
                            email={email}
                            facebook={facebook}
                            instagram={instagram}
                            linkedIn={linkedIn}
                            site={site}
                            local={local}
                            shareTitle={shareTitle}
                            shareText={shareText}
                            logo={logo}
                            perfilImage={perfilImage}
                            backgroundImage={backgroundImage}
                            backgroundColor={backgroundColor}
                            backgroundColorContainerGrau={backgroundColorContainerGrau}
                            backgroundColorContainer1={backgroundColorContainer1}
                            backgroundColorContainerOpacity1={backgroundColorContainerOpacity1}
                            backgroundColorContainer2={backgroundColorContainer2}
                            backgroundColorContainerOpacity2={backgroundColorContainerOpacity2}
                            backgroundColorCardGrau={backgroundColorCardGrau}
                            backgroundColorCard1={backgroundColorCard1}
                            backgroundColorCardOpacity1={backgroundColorCardOpacity1}
                            backgroundColorCard2={backgroundColorCard2}
                            backgroundColorCardOpacity2={backgroundColorCardOpacity2}
                            
                            backgroundColorLinksGrau = {backgroundColorLinksGrau} 
                            backgroundColorLinks1={backgroundColorLinks1}
                            backgroundColorLinksOpacity1={backgroundColorLinksOpacity1}
                            backgroundColorLinks2={backgroundColorLinks2} 
                            backgroundColorLinksOpacity2={backgroundColorLinksOpacity2}

                            linksColorText={linksColorText}
                            linksColorTextOpacity={linksColorTextOpacity}
                           
                            color1Grau={color1Grau}
                        color11={color11}
                        color1Opacity1={color1Opacity1}
                        color12={color12}
                        color1Opacity2={color1Opacity2}

                        color2Grau={color2Grau}
                        color21={color21}
                        color2Opacity1={color2Opacity1}
                        color22={color22}
                        color2Opacity2={color2Opacity2}


                            color3={color3}
                            color4={color4}
                            color5={color5}
                            userNameColor={userNameColor}
                            userNameColorOpacity={userNameColorOpacity}
    
                            occupationColor={occupationColor}
                            occupationColorOpacity={occupationColorOpacity}

                            viewWhatsApp={viewWhatsApp}
                            viewCellPhoneNumber={viewCellPhoneNumber}
                            viewPhoneNumber={viewPhoneNumber}
                            viewEmail={viewEmail}
                            viewFacebook={viewFacebook}
                            viewInstagram={viewInstagram}
                            viewLinkedIn={viewLinkedIn}
                            viewSite={viewSite}
                            viewLocal={viewLocal}
                            viewBackgroundImage={viewBackgroundImage}
                            viewLogo={viewLogo}
                            viewPerfil={viewPerfil}
                            

                        />
                    </div>
                )
            default:
                return (
                    <h1>nenhum</h1>
                )
        }
    }


    return (
        <section>

            <div>

                {selectTemplate()}
            </div>

        </section>
    )

}

export default VCard