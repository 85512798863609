import { Link, useNavigate } from 'react-router-dom'
import api from '../../utils/api'

import example from './ExamplesVcard'
import './CreateVcard.css'
import { useState } from 'react'


function CreateVcard() {
    const [token] = useState(localStorage.getItem('token') || '')
    console.log(token)
    const navigate = useNavigate()

    async function createNewVcard(vcard) {
        let msgType = 'success'
        const data = await api.post('/vcard/register', vcard, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        }).then((response) => {
            return response.data
        })
            .catch((err) => {
                msgType = 'error'
                return err.response.data
            })
        if (msgType !== 'error') {
            console.log("redirect")
            navigate('/')
        }

    }
    return (
        <section className='body-create-vcard'>
            
                <div className='box-container-create-vcard'>
                    <div className='box-create-card-button'>

                        <div className='create-card-button' onClick={() => createNewVcard(example)}>
                            Criar cartão Virtual
                        </div>

                    </div>
                </div>
            

        </section>
    )
}

export default CreateVcard